<template>
  <div class="past-cohorts">
    <CurveSection color="secondary" top="true">
      <HeroSection
        title="Past Cohorts"
        detail="Each year, SanThit searches for the most promising early-stage founders
      and startups. Below are some of those startups and founders that we've had
      the privilege of supporting."
        :image="require('../assets/past_cohorts_hero.png')"
      />
    </CurveSection>
    <CurveSection color="white">
      <div id="finalists">
        <div class="title-area" v-show="events.length > 0">
          <h2
            class="title-secondary"
            :data-content="`${events[currentFinalistYear]} Finalists`"
          >
            {{ events[currentFinalistYear] }} Finalists
          </h2>
          <img
            class="arrow arrow-left"
            src="../assets/icons/left-arrow-dark.svg"
            alt="left arrow"
            @click="prevYearFinalists"
          />
          <img
            class="arrow arrow-right"
            src="../assets/icons/right-arrow-dark.svg"
            alt="right arrow"
            @click="nextYearFinalists"
          />
        </div>

        <div v-for="(finalist, i) in getFinalists" class="story" :key="i">
          <h3
            class="title-secondary mt-3"
            :data-content="finalist.attributes.name"
          >
            {{ finalist.attributes.name }}
          </h3>
          <p>
            {{ finalist.attributes.detail }}
          </p>
        </div>
      </div>
    </CurveSection>

    <CurveSection color="primary" fullWidth>
      <div id="cohorts">
        <div class="title-area mb-3" v-show="events.length > 0">
          <h2
            class="title"
            :data-content="`${events[currentCohortYear]} Cohorts`"
          >
            {{ events[currentCohortYear] }} Cohorts
          </h2>
          <img
            class="arrow arrow-left"
            src="../assets/icons/left-arrow-dark.svg"
            alt="left arrow"
            @click="prevYearCohorts"
          />
          <img
            class="arrow arrow-right"
            src="../assets/icons/right-arrow-dark.svg"
            alt="right arrow"
            @click="nextYearCohorts"
          />
        </div>

        <div class="cohorts-row">
          <template v-for="cohort in getCohorts" :key="cohort.attributes.id">
            <div class="cohort-container">
              <img
                :src="getImageFromResponse(cohort.attributes.images)"
                alt=""
                class="cohort-image"
              />
            </div>
          </template>
        </div>

        <div class="underline-area">
          <div class="pagination" v-show="displayPagination">
            <h3 class="">{{ displayPagination }}</h3>
            <img
              class="arrow arrow-left"
              src="../assets/icons/left-arrow-dark.svg"
              alt="left arrow"
              @click="prevPage()"
            />
            <img
              class="arrow arrow-right"
              src="../assets/icons/right-arrow-dark.svg"
              alt="right arrow"
              @click="nextPage()"
            />
          </div>

          <img
            class="mt-3"
            src="../assets/space_underline_white.png"
            alt="underline"
          />
        </div>
      </div>
    </CurveSection>
    <CurveSection color="secondary">
      <RocketSection title="Get started today!" />
    </CurveSection>
    <Footer color="primary" />
  </div>
</template>

<script>
import HeroSection from "@/components/HeroSection.vue";
import RocketSection from "@/components/RocketSection.vue";
import CurveSection from "@/components/CurveSection.vue";
import Footer from "@/components/Footer.vue";
import baseURL from "@/helpers/baseURL.js";
import qs from "qs";
// import { backendURL } from "@/config.js";

export default {
  name: "Past Cohorts",
  created() {
    if (this.$store.state.isMenuOpen) this.$store.commit("toggleMenuOpen");
    document.title = this.$router.currentRoute.value.meta.title;
    window.scrollTo({ top: 0, behavior: "smooth" });
    async function fetchDataFromBackend(self) {
      const current = await self.fetchEvents();
      await self.fetchCohorts(current);
      await self.fetchFinalists(current);
    }

    const self = this;
    fetchDataFromBackend(self);
  },
  data() {
    return {
      currentCohortYear: 0,
      currentFinalistYear: 0,
      displayPagination: "",
      cohorts: [],
      finalists: [],
      events: [],
    };
  },
  methods: {
    async fetchEvents() {
      const query = qs.stringify(
        {
          fields: ["name", "current"],
        },
        {
          encodeValuesOnly: true,
        }
      );
      let current = 0;
      const response = await baseURL.get(`api/events?${query}`);

      this.events = response.data.data;
      let data = [];

      Object.entries(this.events).forEach((item, i) => {
        data.push(item[1].attributes.name);
        if (item[1].attributes.current) {
          this.currentCohortYear = i;
          this.currentFinalistYear = i;
          current = i;
        }
      });

      this.events = data;
      return current;
    },
    getImageFromResponse(response) {
      return `${response.data[0].attributes.url}`;
    },
    getThisYear() {
      return new Date().getFullYear().toString();
    },
    nextYearCohorts() {
      const nextYear = this.currentCohortYear + 1;
      if (nextYear < this.events.length) {
        this.currentCohortYear = nextYear;
        this.fetchCohorts(nextYear);
      }
    },
    prevYearCohorts() {
      const prevYear = this.currentCohortYear - 1;
      if (prevYear > -1) {
        this.currentCohortYear = prevYear;
        this.fetchCohorts(prevYear);
      }
    },
    nextYearFinalists() {
      const nextYear = this.currentFinalistYear + 1;
      if (nextYear < this.events.length) {
        this.currentFinalistYear = nextYear;
        this.fetchFinalists(nextYear);
      }
    },
    prevYearFinalists() {
      const prevYear = this.currentFinalistYear - 1;
      if (prevYear > -1) {
        this.currentFinalistYear = prevYear;
        this.fetchFinalists(prevYear);
      }
    },
    fetchCohorts(year) {
      let isAlreadyExist = false;

      this.cohorts.forEach((cohort) => {
        if (cohort.year == year) isAlreadyExist = true;
      });

      if (!isAlreadyExist) {
        const query = qs.stringify(
          {
            fields: ["name", "detail"],
            filters: {
              showCase: {
                $eq: true,
              },
              event: {
                name: {
                  $eq: this.events[year],
                },
              },
            },
            populate: {
              images: {
                sort: ["id:asc"],
              },
            },
          },
          {
            encodeValuesOnly: true,
          }
        );

        baseURL
          .get(`api/cohorts?${query}`)
          .then((response) => {
            this.cohorts.push({
              year,
              data: response.data.data,
              currentPage: 1,
              totalPage: Math.ceil(response.data.meta.pagination.total / 6),
            });
            this.displayPagination = `1/${Math.ceil(
              response.data.meta.pagination.total / 6
            )}`;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    fetchFinalists(year) {
      let isAlreadyExist = false;
      this.finalists.forEach((data) => {
        if (data.year == year) {
          isAlreadyExist = true;
        }
      });

      if (!isAlreadyExist) {
        const query = qs.stringify(
          {
            fields: ["name", "detail"],
            filters: {
              finalists: {
                $eq: true,
              },
              event: {
                name: {
                  $eq: this.events[year],
                },
              },
            },
            populate: {
              images: {
                sort: ["id:asc"],
              },
            },
          },
          {
            encodeValuesOnly: true,
          }
        );

        baseURL
          .get(`api/cohorts?${query}`)
          .then((response) => {
            this.finalists.push({
              year,
              data: response.data.data,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    nextPage() {
      let displayPagination = "";
      const newCohorts = this.cohorts.map((cohort) => {
        if (this.currentCohortYear == cohort.year) {
          if (cohort.totalPage > 1 && cohort.currentPage < cohort.totalPage) {
            cohort.currentPage += 1;
            displayPagination = `${cohort.currentPage}/${cohort.totalPage}`;
            return cohort;
          } else {
            displayPagination = `${cohort.currentPage}/${cohort.totalPage}`;
            return cohort;
          }
        } else {
          displayPagination = `${cohort.currentPage}/${cohort.totalPage}`;
          return cohort;
        }
      });
      this.displayPagination = displayPagination;
      this.cohorts = newCohorts;
    },
    prevPage() {
      let displayPagination = "";
      const newCohorts = this.cohorts.map((cohort) => {
        if (this.currentCohortYear == cohort.year) {
          if (cohort.currentPage > 1) {
            cohort.currentPage -= 1;
            displayPagination = `${cohort.currentPage}/${cohort.totalPage}`;
            return cohort;
          } else {
            cohort.currentPage = 1;
            displayPagination = `${cohort.currentPage}/${cohort.totalPage}`;
            return cohort;
          }
        } else {
          displayPagination = `${cohort.currentPage}/${cohort.totalPage}`;
          return cohort;
        }
      });
      this.displayPagination = displayPagination;
      this.cohorts = newCohorts;
    },
  },
  computed: {
    calcuateStart() {
      let currentPage = 1;
      this.cohorts.forEach((cohort) => {
        if (this.currentCohortYear == cohort.year) {
          currentPage = cohort.currentPage;
        }
      });
      return currentPage == 1 ? 0 : 6 * (currentPage - 1);
    },
    getCohorts() {
      let result = [];
      this.cohorts.forEach((cohort) => {
        if (this.currentCohortYear == cohort.year) {
          result = [...cohort.data];
        }
      });
      return result.splice(+this.calcuateStart, 6);
    },

    getFinalists() {
      let result = [];
      this.finalists.forEach((finalist) => {
        if (this.currentFinalistYear == finalist.year) {
          result = [...finalist.data];
        }
      });

      return result;
    },
  },
  components: {
    HeroSection,
    RocketSection,
    CurveSection,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/definations.scss";
@import "@/scss/pastCohorts.scss";
</style>
